import React from 'react'


class Gago extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="m-auto" style={{ maxWidth: '960px' }}>
                    <div className="container ">
                        <div className="my-3 gago-main-container">
                            <div className="p-1">
                                <div className="row">
                                    <div className="col-12 text-center">

                                        <img className="img-fluid" style={{ maxWidth: '99%' }} src="./images/banner-resized.jpg" />
                                        <img style={{ position: 'absolute', 'width': '60%', top: '18%', left: '43%', zIndex: '100' }} src="./images/Mascot-resized.png" />

                                    </div>
                                </div>
                                <div className="row mx-auto">
                                    <div className="col-lg-4 col-md-12 mx-0 px-0">
                                        <div className="m-1 p-2 gago-box">

                                            <div className="text-center" style={{ lineHeight: "50px", fontWeight: 'bold', fontSize: '36px' }}>
                                                One Platform to
                                            </div>
                                            <div className="text-center m-0 p-0" style={{ lineHeight: "20px", fontWeight: 'bold', fontSize: '36px' }}>
                                                RULE THEM ALL!
                                            </div>
                                            <div className="text-center mt-3 mx-auto" style={{ width: '80%', fontSize: '18px' }}>
                                                Download and enjoy games, in-game chatting and endless fun, all while making new friends. Gago will be your escape route. Your favorite games now just a click away
                                            </div>
                                        </div>
                                        {/* <img className="img-fluid" src='./images/bannermock.png'></img> */}
                                    </div>

                                    <div className=" col-lg-4 col-md-12 mx-0 px-0">
                                        <div className="m-1 p-3 gago-box">

                                            <div className="text-center mt-3" style={{ lineHeight: "50px", fontWeight: 'bold', fontSize: '32px' }}>
                                                Download Now
                                            </div>
                                            {/* <div className="text-center" style={{ lineHeight:"20px",fontWeight:'bold',fontSize: '32px' }}>
                                                Now
                                            </div>  */}
                                            <div className="text-center mt-3" style={{ fontSize: '12px' }}>
                                                <div className="pt-2">
                                                    <a href="https://play.google.com/store/apps/details?id=com.gago.multiplayergames">
                                                        <img src="./images/google-icon.png" width='80%' />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12 mx-0 px-0">
                                        <div className="m-1 p-1 gago-box">

                                            <div className="text-left ml-2 pt-2" style={{ lineHeight: "50px", fontWeight: 'bold', fontSize: '36px' }}>
                                                Game On
                                            </div>
                                            <div className="text-left ml-2" style={{ lineHeight: "20px", fontWeight: 'bold', fontSize: '36px' }}>
                                                the GO
                                            </div>
                                            <div className="text-left ml-2" style={{ lineHeight: "50px", fontWeight: 'bold', fontSize: '36px' }}>
                                                With new Friends
                                            </div>

                                            <div className="mt-2 text-center">
                                                <div className="">

                                                    <img loading="lazy" style={{ borderRadius: '12px' }} className="p-1" width="86px" height='' src="./images/a.jpg" />
                                                    <img loading="lazy" style={{ borderRadius: '12px' }} className="p-1" width="86px" height='' src="./images/b.jpg" />
                                                    <img loading="lazy" style={{ borderRadius: '12px' }} className="p-1" width="86px" height='' src="./images/c.jpg" />
                                                </div>
                                                <div className=" mt-1">
                                                    <img loading="lazy" style={{ borderRadius: '12px' }} className="p-1" width="86px" height='' src="./images/d.jpg" />
                                                    <img loading="lazy" style={{ borderRadius: '12px' }} className="p-1" width="86px" height='' src="./images/e.jpg" />
                                                    <img loading="lazy" style={{ borderRadius: '12px' }} className="p-1" width="86px" height='' src="./images/f.jpg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gago