import React, { useEffect, useState } from 'react';
import './App.css';


class App extends React.Component {



  constructor(props) {
    super(props);
    
    var url = new URL(window.location.href);
    var currentLang = url.searchParams.get("lang");
    

    this.state = {
      language: (currentLang === 'eng' || currentLang === 'ar')?currentLang:'eng',
      currentState: 'send', // other will be 'confirm' for confirm pin , wait for please wait, login
      phone: '',
      pin: '',
      Alert: "",
      btnText: {
        eng: 'Subscribe',
        ar: 'الإشتراك'
      },
      labelText: {
        eng: 'Enter your mobile no',
        ar: 'أدخل رقم هاتفك للتنزيل'
      },
    }

  }

  componentDidMount() {

  }
  onValueChange(value) {
    if (this.state.currentState === 'send') {
      this.setState({
        phone: value
      })
    } else {
      this.setState({
        pin: value
      })
    }
  }
  changeLanguage() {
    let currentLang = this.state.language;
    let nextLang = currentLang;
    (currentLang === 'eng') ? nextLang = 'ar' : nextLang = 'eng';
    this.setState(
      {
        language: nextLang,
        Alert: ''
      }
    );
  }
  async onSendPin() {
    if (!this.state.phone || (((this.state.phone.trim().length) !== 12) && ((this.state.phone.trim().length) !== 10))) {
      this.setState({
        Alert: (this.state.language === 'eng') ? 'Invalid mobile number' : 'رقم الجوال غير صالح'
      })
      return;
    }

    if ((this.state.phone.trim().length) === 10) {
      let substring = this.state.phone.substring(1);
      let number = '966' + substring;
      await this.setState({
        phone: number
      });
    }
    this.setState({
      currentState: 'wait',
      Alert: '',
      btnText: {
        eng: 'Please wait...',
        ar: 'ارجوك انتظر'
      }
    })
    let API = await fetch(`http://44.224.168.120:8078/kidotainment/send_pin?service_id=120&msisdn=${this.state.phone}&partner_id=2`);
    console.log("send pin",API);
    let Response = await API.json();




    if (Response.status === 0 && Response.message == 'Already Sub') {
      // already subscribed
      await this.setState({
        Alert: (this.state.language === 'eng') ? 'Congratulaions, you are already subscribed to our service!' : 'تهانينا ، أنت مشترك بالفعل في خدمتنا'
      })

      await setTimeout(() => {
        window.location.href = `http://kidotainment.com?msisdn=${this.state.phone}&telco=mobily`
      }, 2000)

    }



    else if (Response.status === 0) {
      this.setState({
        Alert: (this.state.language === 'eng') ? "Invalid mobile number, please try again" : 'رقم هاتف غير صحيح ، يرجى المحاولة مرة أخرى',
        currentState: 'send',
        btnText: {
          eng: 'Subscribe',
          ar: 'الإشتراك'
        }
      })

    }
    else if (Response.status === 1) {
      document.getElementById('phone').value = "";
      document.getElementById('phone').placeholder = "xxxxx";
      this.setState({
        currentState: 'confirm',
        Alert: '',
        btnText: {
          eng: 'Subscribe',
          ar: 'الإشتراك'
        },
        labelText: {
          eng: 'Enter the PIN Code Received in SMS',
          ar: 'أدخل رمز PIN المستلم في رسالة نصية قصيرة'
        }
      })
    }
    console.log(Response);


  }



  async onConfirmPin() {
    this.setState({
      currentState: 'wait',
      Alert: '',
      btnText: {
        eng: 'Please wait...',
        ar: 'ارجوك انتظر'
      }
    })
    let API = await fetch(`http://44.224.168.120:8078/kidotainment/confirm_pin?service_id=120&msisdn=${this.state.phone}&partner_id=2&pincode=${this.state.pin}`);
    let Response = await API.json();
    if (Response.status === 1) {
      // success
      await this.setState({
        Alert: (this.state.language === 'eng') ? 'Congratulaions, you have successfully subscribed to our service!' : 'تهانينا ، لقد اشتركت بنجاح في خدمتنا!'
      })

      await setTimeout(() => {
        window.location.href = `http://kidotainment.com?msisdn=${this.state.phone}&telco=mobily`
      }, 2000)
    }

    else {
      this.setState({
        currentState: 'confirm',
        Alert: (this.state.language === 'eng') ? 'invalid pin code, please try again' : 'رمز PIN غير صالح ، يرجى المحاولة مرة أخرى',
        btnText: {
          eng: 'Subscribe',
          ar: 'الإشتراك'
        }
      })
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Header language={this.state.language} />

        <Content>
          <div class=" d2 text-center" style={{ "fontWeight": "normal", 'color': 'white' }}>{this.state.Alert}</div>
          <div className="sub-section">

            <label for="phone" className={`label-phone-${this.state.language}`}>{this.state.labelText[this.state.language]}</label>
            <input type="number" placeholder="966XXXXXXXXX" id="phone" onChange={(e) => { this.onValueChange(e.target.value) }}
              style={{ 'direction': (this.state.language === 'eng') ? 'ltr' : 'rtl', 'backgroundPosition': (this.state.language === 'eng') ? '10px 30px' : '98% 30px', 'paddingRight': (this.state.language === 'eng') ? '10px' : '65px', 'paddingLeft': (this.state.language === 'eng') ? '65px' : '10px' }}
            />

            <button disabled={(this.state.currentState === 'wait') ? true : false} className="sub-btn" onClick={
              (this.state.currentState === 'send') ?
                () => this.onSendPin() : () => this.onConfirmPin()
            }>
              <div class="d1" style={{ "fontWeight": (this.state.language === 'eng') ? "normal" : 'bolder' }}>{this.state.btnText[this.state.language]}</div>
            </button>

          </div>
          <img className="lang-btn" onClick={() => { this.changeLanguage() }} src={(this.state.language === 'eng') ? './images/btn-arabic.png' : "./images/btn-eng.png"} alt="" />
          {/* <img className="zain-logo" src='./images/zain-top.png' alt="" /> */}
          <div class="p-2 mt-2 d2 text-center" style={{ "fontWeight": (this.state.language === 'eng') ? "normal" : 'bolder', 'color': 'white' }}>
            {
              (this.state.language === 'eng') ?
                'Service price is 1 SAR per day and renews daily, to unsubscribe send U 20 to 600159.'
                :
                'سعر الخدمة 1 ريال سعودي في اليوم ويتجدد يوميًا ، لإلغاء الاشتراك أرسل "U 20" إلى "600159".'
            }
          </div>
        </Content>

        <Footer language={this.state.language} />
      </div>
    );
  }




}
function Header(props) {


  return (
    <div>

      <div className="d-none d-lg-block ">
        <img className="img-fluid" width='100%' src={`./images/banner-img-large-${props.language}.jpg`} ></img>
      </div>

      <div className="d-lg-none ">
        <img className="img-fluid" width='100%' src={`./images/banner-img-small-${props.language}.jpg`} ></img>
      </div>

    </div>
  );

}

function Content(props) {
  return (

    <div className="my-container">
      {props.children}
    </div>
  )
}

function Footer(props) {
  return (
    <div>

      <div className="d-none d-lg-block ">
        <img className="img-fluid" width="100%" src={`./images/category-large.jpg`} ></img>
      </div>

      <div className="d-lg-none">
        <img className="img-fluid" width="100%" src={`./images/category.jpg`} ></img>
      </div>

      <div className="disclaimer-text text-center">
        {/* The service price is 7.50 SAR per week and renewed weekly. To cancel your subscription please send U3 to 801761. */}
        <p className="p-2" style={{ 'fontWeight': (props.language === 'eng') ? 'normal' : 'bold' }}>
          {
            (props.language === 'eng') ?
              'By subscribing to service you are accepting all terms and conditions of the service.'
              :
              'من خلال الاشتراك في الخدمة ، فإنك تقبل جميع شروط وأحكام الخدمة'
          }
        </p>
      </div>
    </div>
  );
}

export default App;
